@tailwind base;
@tailwind components;
@tailwind utilities;

/* CAROUSEL SPECIFIC STYLES */
.rec-arrow {
    display:none;
}

.rec-dot {
    display:none;
}
/* END CAROUSEL STYLES */
.rbc-event, .rbc-day-slot .rbc-background-event{
    background-color: #F89767;
}

.rbc-event.rbc-selected, .rbc-day-slot .rbc-selected.rbc-background-event {
    background-color: rgba(248,151,103,0.7);
}

.rbc-event:focus, .rbc-day-slot .rbc-background-event:focus{
    outline: none;
}


/* angled div */
.slant-blue {
    position: relative;
    overflow: visible;
    z-index: 1;
  }
  
  /* where the magic happens */
  .slant-blue:before, .slant-blue:after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    background: inherit;
    z-index: -1;
    top: 0;
    transform-origin: left top;
    transform: skewY(-3deg);
  }
  
  .slant-blue:after {
    bottom: 0;
    transform-origin: right bottom;
    transform: skewY(-3deg);
  }